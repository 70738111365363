import { OrderPaymentsType, OrderStatus, OrderType } from '@travelwin/core';

/**
 * Currently bundle order is called TOPUP in backend.
 *   In first phase of implementing proper top up, we will consider both TOPUP and BUNDLE
 *   to allow all orders to transition to new stage using BUNDLE.
 *
 *   Once proper top up functionality is implemented, this will indicate the right type
 */
export const isBundleOrder = (order: OrderType) => {
  return (
    order.items[0].type.id === 'TOPUP' || order.items[0].type.id === 'BUNDLE'
  );
};

export const isFinalStatus = (status: string) => {
  return (
    status === OrderStatus.Completed ||
    status === OrderStatus.Refunded ||
    status === OrderStatus.Failed
  );
};

export const isActivatingStatus = (status: string | undefined) => {
  return (
    status === OrderStatus.ProcessingApplyingTopUp ||
    status === OrderStatus.ProcessingApplyingBundle ||
    status === OrderStatus.ProcessingObtainingActivationCode ||
    status === OrderStatus.ProcessingWaitingNetworkActivation
  );
};

export const isErrorStatus = (
  status: string | undefined,
  refundReason: string | undefined,
) => {
  const isAutoRefund =
    refundReason === 'ORDER_FAILED' || refundReason === 'ACTIVATION_FAILED';

  return (
    status === OrderStatus.Failed ||
    (status === OrderStatus.Refunded && isAutoRefund)
  );
};

export const isRefundedByUser = (
  status: string | undefined,
  refundReason: string | undefined,
) => {
  return status === OrderStatus.Refunded && refundReason === 'USER_REQUESTED';
};

export const getDiscountFromPayments = (payments: OrderPaymentsType[]) => {
  return payments.reduce(
    (acc, payment) =>
      payment.payment_type.id === 'VOUCHER' ? acc + payment.price : acc,
    0,
  );
};

export const isActivating = (order: OrderType) => {
  return isActivatingStatus(order.status?.id);
};

export const isActivated = (order: OrderType) => {
  return order.status.id === OrderStatus.Completed;
};

export const filterInactiveOrders = (orders?: OrderType[]) => {
  return orders?.filter((o) => !isActivated(o));
};

export const isExpired = (order: OrderType) => {
  // We'll understand the plan was expired
  // if the operator does not provide the data required to perform installation
  const { qr_code_string } = order.items[0];
  return !qr_code_string;
};

export const isRefunded = (order: OrderType) => {
  return order.status.id === OrderStatus.Refunded;
};

export const isFailed = (order: OrderType) => {
  return order.status.id === OrderStatus.Failed;
};

export const isActivatedNotExpired = (order: OrderType) => {
  return isActivated(order) && !isExpired(order);
};

export const isFreeTrialOrder = (order: OrderType) => {
  return order.payment_type.id === 'FREE_TRIAL';
};
